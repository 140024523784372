import { render, staticRenderFns } from "./txt-with-img.vue?vue&type=template&id=6cc2aae8&"
import script from "./txt-with-img.vue?vue&type=script&lang=ts&"
export * from "./txt-with-img.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6cc2aae8')) {
      api.createRecord('6cc2aae8', component.options)
    } else {
      api.reload('6cc2aae8', component.options)
    }
    module.hot.accept("./txt-with-img.vue?vue&type=template&id=6cc2aae8&", function () {
      api.rerender('6cc2aae8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/table/cells/txt-with-img.vue"
export default component.exports