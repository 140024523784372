var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { class: _vm.data.class }, [
    _c("span", { domProps: { textContent: _vm._s(_vm.data.txt) } }),
    _c("img", {
      style: _vm.data.styleImg,
      attrs: { src: _vm.data.imgUrl, alt: "" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }